import { render, staticRenderFns } from "./search.vue?vue&type=template&id=7cf31cae&scoped=true"
import script from "./search.vue?vue&type=script&lang=js"
export * from "./search.vue?vue&type=script&lang=js"
import style0 from "./search.vue?vue&type=style&index=0&id=7cf31cae&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf31cae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/Platform-foundation-mob_0jLc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cf31cae')) {
      api.createRecord('7cf31cae', component.options)
    } else {
      api.reload('7cf31cae', component.options)
    }
    module.hot.accept("./search.vue?vue&type=template&id=7cf31cae&scoped=true", function () {
      api.rerender('7cf31cae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/search.vue"
export default component.exports